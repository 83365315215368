.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

/* Custom CSS class for the notification */
.custom-toast {
  min-width: 35rem;
  min-height: 5rem;
  margin-bottom: 0.25rem;
  overflow: hidden;
  z-index: 1000;
  animation: fadeInUp 0.5s, fadeOutDown 0.5s 4.5s; /* Animation for appearance and disappearance */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.pointer {
  cursor: pointer;
}

// two divs side by side (float, flexbox, CSS grid)

.float-container {
  padding: 0px;
}

.float-child {
  float: left;
  padding: 0px;
}

.tittle {
  color: var(--milstone-prim, #14027a);
  font-family: Poppins;
  font-size: 37px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-title {
  color: var(--Black, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate~.mdc-checkbox__background {
  border-color: var(--primary-14027A) !important;
  background-color: var(--primary-14027A) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: var(--primary-14027A) !important;
  background-color: var(--primary-14027A) !important;
}

.mat-mdc-header-cell .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
  background-color: white!important;
}

.daterangepicker .ranges li.active {
  background-color: #160278!important;
  color: #fff;
}

/*.cdk-overlay-pane{
  width: 180px!important;
}*/

.mat-mdc-option{
  font-size: 15.5px;
}

@media (min-width: 992px) {
  .modal-size .modal-lg{
      --bs-modal-width: 1000px;
  }
}

.mat-mdc-cell{
  vertical-align: middle; 
}

.cdk-overlay-container {
  z-index: 100000!important;  /* Assurez-vous que la liste déroulante soit au-dessus des autres éléments */
}

.sort {
  background: transparent;
  border: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
}

.row-indicator{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 20px;
  width: 100% !important;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.bg-blue-385888{
  background-color: #385888;
}